<template>
    <button v-if="type === 'link'"
            class="px-6 py-3 flex gap-2 justify-center items-center self-stretch relative w-full h-14">
        <span class="font-medium leading-6 text-lg text-black-50 underline">{{ text }}</span>
    </button>
    <button v-else-if="type === 'white'"
            class="px-6 py-3 border-2 rounded-[28px] border-[#efedf2] flex gap-2 justify-center items-center self-stretch relative w-full h-14 hover:border-gray-40">
        <span class="font-medium leading-6 text-lg text-black-50">{{ text }}</span>
    </button>
    <button v-else
            class="rounded-[28px] px-6 py-3 flex gap-2 justify-center items-center self-stretch relative w-full h-14 bg-[#72e0d8]">
        <span class="font-medium leading-6 text-lg text-[#292b33]">{{ text }}</span>
    </button>
</template>

<script>
export default {
    props: ["text", "type"]
}
</script>